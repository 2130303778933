/* get in here https://codepen.io/malavigne/pen/grQGJd */

/* Arrow & Hover Animation */
#more-arrows {
  width: 75px;
  height: 65px;

  &:hover {
    polygon {
      fill: #fff;
      transition: all 0.2s ease-out;

      &.arrow-bottom {
        transform: translateY(-18px);
      }

      &.arrow-top {
        transform: translateY(18px);
      }
    }
  }
}

polygon {
  fill: #fff;
  transition: all 0.2s ease-out;

  &.arrow-middle {
    opacity: 0.75;
  }

  &.arrow-top {
    opacity: 0.5;
  }
}
